import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    elkGroveImage: file(relativePath: { eq: "elk-grove.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function ElkGrovePage({ data }) {
  return (
    <MainLayout
      title="Affordable Locksmith Service in Elk Grove, CA - 24 Hour"
      description="Need commercial, residential and automotive security? We can help! We offer 24 hour fast friendly locksmith service. Licensed & Insured. 916-995-0135
      "
      offset
    >
      <ContactHeading background={data.elkGroveImage.childImageSharp.fluid}>
        <h1 className="text-center">Elk Grove Locksmith</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Elk Grove Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2>Elk Grove's Leading Locksmith</h2>
        <p className="indent">
          Welcome to Swift Locksmith Elk Grove, where our mission is to provide
          you with safety and security in the Elk Grove community. We provide a
          vast selection of security services for residential, automotive and
          commercial needs. As a local licensed and bonded locksmith company, we
          specialize in making you feel more safe. Our experienced locksmith
          technicians work around the clock and provide 24/7 emergency services
          so you are never left feeling out of luck.
        </p>
        <p className="indent mb-0">
          Our professional and trained employees always strive to provide a fast
          response to any and every locksmith need. In addition, we tailor our
          locksmith services to fulfill each of our customers' particular needs.
          Whether you are looking to replace your home locks, lost your car
          keys, or rekeying your office, Swift Locksmith in Elk Grove is your go
          to company. Our team always strives to offer you exceptional,
          trustworthy customer service.
        </p>
      </Container>
      <section>
        <div className="background-content bg-dark text-white">
          <Container>
            <h2>Services We Offer In Elk Grove, CA</h2>
            <h3 className="text-primary">Residential Locksmith</h3>
            <p>
              We offer a variety of residential locksmith services for your
              house from basic to high security needs. Whether you need to get
              your locks changed, re-key your lock cylinders, or simply trying
              to modify your mailbox lock, we'll take care of you.
            </p>
            <h3 className="text-primary">Commercial Locksmith</h3>
            <p>
              Get professional locksmith services for your Elk Grove business.
              We've been serving local Elk Grove businesses for over 10 years
              and our locksmith technicians are experts at providing different
              security solutions for all types of businesses. From door hardware
              into security solutions, we do it all.
            </p>
            <h3 className="text-primary">Automotive Locksmith</h3>
            <p>
              Locked out of your car/truck? Lost your keys? Swift Locksmith in
              Elk Grove will come to you to unlock your vehicle, duplicate a
              brand new key if necessary or fix your lock on the spot.
            </p>
            <h3 className="text-primary">Emergency Locksmith</h3>
            <p className="indent mb-0">
              Elk Grove Swift Locksmith is open to serve all your locksmith
              needs 24/7. Call us today, day or night, and we will come to
              wherever you are. Our professional staff will offer you fast,
              reliable and excellent customer service.
            </p>
          </Container>
        </div>
      </section>
      <section className="d-none d-md-block">
        <Row noGutters>
          <Col>
            <Img
              fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
              alt="leo-with-rekey-set"
              style={{ height: "100%", width: "auto" }}
            />
          </Col>
          <Col>
            <Img
              fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
              alt="locksmith-van-in-city"
              style={{ height: "100%", width: "auto" }}
            />
          </Col>
          <Col>
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
              style={{ height: "100%", width: "auto" }}
            />
          </Col>
        </Row>
      </section>
      <Container as="section" className="mb-5">
        <h2>Choosing Swift Locksmith in Elk Grove</h2>
        <p className="indent">
          Swift Locksmith in Elk Grove provides reassurance for those that are
          looking to secure their property and offers emergency response to get
          you back into your house, office, or vehicle. Our friendly,
          professional locksmiths and technicians have the knowledge and
          expertise to unlock and install any kind of lock needed.
        </p>
        <p className="indent mb-0">
          We know how difficult and stressful it can be when you lose your keys
          or are locked out of your home or car and that is why we always
          respond quickly to every call that we receive. Our technicians always
          arrive with the right gear and materials to help resolve your
          locksmith issue and get you back to normal routine. Swift Locksmith is
          fully licensed and insured to work as locksmiths in Elk Grove, CA and
          therefore are dedicated to serving the Elk Grove community.
        </p>
      </Container>
    </MainLayout>
  )
}

export default ElkGrovePage
